import Swiper from '../../../node_modules/swiper/swiper-bundle';

let mySwiper = new Swiper('.class', {});


// let stepsSwiper = new Swiper('.exampleSwiper', {
//     slidesPerView:'auto',
//     scrollbar: {
//         el: '.swiper-scrollbar',
//         hide: false,
//     },

//     navigation: {
//         nextEl: '.steps__button-next',
//         prevEl: '.steps__button-prev',
//     },
// });
